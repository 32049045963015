import React from "react";
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Constants from './../utilities/Constants';
import { Divider } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import ICard from "../utilities/Icard";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const voters = Constants?.voters;

const Details = () => {

  const { id } = useParams();
  const [voter, setVoter] = React.useState();
  const [number, setNumber] = React.useState('');
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if(Constants?.selectedConstituency === undefined) {
        Constants.navigate('/')
    }
    setVoter(Constants.voters.filter(e => e.m_id == id)[0]);
    setNumber(Constants.voters.filter(e => e.m_id == id)[0]?.mobileno)
  }, [id])

  const getMessage = type => {

    let headers = Constants?.lables.filter(l => l.constituency_id == Constants?.selectedConstituency?.constituency_id)
                            .filter(l => l.language_code == Constants.selectedLanguage)
    let obj = {}
    let data = headers.map(h => {
      let key = h.col
      let val = voter[key]
      obj[`{${key}}`] = val
    })

    let msg = Constants.messages.filter(m => m.constituency_id == Constants.selectedConstituency.constituency_id)
                                .filter(m => m.type == type)
                                .filter(m => m.lang_code == Constants.selectedLanguage)
    
    msg = msg.length ? msg[0]?.message : ''

    var new_str = msg;

    for (var key in obj) {
      if (!obj.hasOwnProperty(key)) {
          continue;
      }
      
      new_str = new_str?.replace(new RegExp(key, "g"), obj[key]);
    }

    return new_str; 
  }

  const sendWhatsapp = () => {
    
      let message = getMessage('whatsapp').replace('**', '').replace('**', '').replace('**', '').replace('**', '').replace('**', '').replace('**', '').replace('**', '')

      let url     = number !== null ? 
                  `https://wa.me/${number.replace(/[^\w\s]/gi, "").replace(/ /g, "")}?&text=${encodeURIComponent(message)}&app_absent=0` 
                  :
                  `https://wa.me/?&text=${encodeURIComponent(message)}&app_absent=0`;
      window.open(url);
    
  }

  const sendSMSapp = () => {
    if(number) {
      let message = getMessage('sms').replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '')
      
      let url     = `sms://${number.replace(/[^\w\s]/gi, "").replace(/ /g, "")}/?body=${encodeURI(message)}`
      window.open(url);
    } else {
      setOpen(true)
      setInterval(() => setOpen(false), 3000)
    }
  }


  return (
    voter ? <> 
      <Card >
          <CardHeader title={
            Constants.languages[Constants.selectedLanguage].details.ttl.pageTitle 
          } sx={{ textAlign: 'center' }} className="bg-brand"/>
          <Divider className="bg-brand" />
          <CardContent>
           <ICard e={voter} />
           <Grid item xs={12}>
              <TextField
                  sx={{mt: 2}}
                  label={Constants.languages[Constants.selectedLanguage].details.lbl.mobileNo}
                  id="outlined-size-small-mobile-no"
                  defaultValue={number}
                  size="small"
                  fullWidth
                  value={number}
                  onChange={e => setNumber(e.target.value)}
              />
            </Grid>
          </CardContent>
          <CardActions>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                              setLoading(true)
                              Constants.navigate('/list')
                              setTimeout(() => setLoading(false), 1000)
                            }
                        }
                    >
                        <span>
                          {'< '} {Constants.languages[Constants.selectedLanguage].details.btn.back}
                        </span>
                    </LoadingButton>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                                setLoading(true)
                                sendWhatsapp()
                                setTimeout(() => setLoading(false), 1000)
                            }
                        }
                    >
                        <span>
                          {Constants.languages[Constants.selectedLanguage].details.btn.sendWhatsapp}
                        </span>
                    </LoadingButton>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                              setLoading(true)
                              sendSMSapp()
                              setTimeout(() => setLoading(false), 1000)
                            }
                        }
                    >
                        <span>
                          {Constants.languages[Constants.selectedLanguage].details.btn.sendSms}
                        </span>
                    </LoadingButton>
                    <Snackbar
                        open={open}
                    >
                        <Alert severity="error">Please provide mobile number.</Alert>
                    </Snackbar>
          </CardActions>
      </Card>
    </> : 
    <>
      <Skeleton variant="rectangular" width={'100%'} height={450} animation="wave" />
    </>
  )

}
export default Details;
